<template>
  <b-card v-bind="$attrs">
    <b-card-header class="text-center">
      <b-card-title class="font-bold">
        <slot name="header"> </slot>
      </b-card-title>
      <slot name="back-header"></slot>
    </b-card-header>
    <b-card-body class="text-center">
      <slot name="body"></slot>
    </b-card-body>
    <b-card-footer bottom>
      <slot name="footer"></slot>
    </b-card-footer>
  </b-card>
</template>
<script>
export default {
  name: "CardFram",
  inheritAttrs: false
};
</script>
